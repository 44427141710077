.p-inputgroup {
    display: flex;
    align-items: center;
  }
  
  .p-inputgroup-addon {
    background-color: #BEE3F8;
    border: 1px solid #BEE3F8;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  