/* Estilos para o Checkbox de reprovação */
.disapprove-checkbox .p-checkbox {
    width: 24px; /* Aumenta a largura do checkbox */
    height: 24px; /* Aumenta a altura do checkbox */
}

.disapprove-checkbox .p-checkbox-box {
    border-color: red; /* Cor da borda para vermelho */
    width: 100%; /* Ajusta a largura para preencher o container */
    height: 100%; /* Ajusta a altura para preencher o container */
    position: relative; /* Necessário para posicionar o ícone dentro do checkbox */
}

.disapprove-checkbox .p-checkbox-box.p-highlight {
    background-color: red !important; /* Fundo vermelho quando selecionado */
    color: white !important; /* Cor do ícone/texto para branco */
}

.disapprove-checkbox .p-checkbox-box.p-highlight:after {
    content: '❌'; /* Adiciona um X quando selecionado */
    font-size: 18px; /* Ajusta o tamanho do X conforme necessário */
    color: white; /* Cor do X para branco */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.disapprove-checkbox .p-checkbox-icon {
    display: none; /* Esconde o ícone padrão */
}




/* Estilos para o Checkbox de aprovação */
.approve-checkbox .p-checkbox-box {
    border-color: green; /* Cor da borda para verde */
}

.approve-checkbox .p-checkbox-box.p-highlight {
    background-color: green !important; /* Fundo verde quando selecionado */
    color: black !important; /* Cor do ícone/texto para preto */
}

.approve-checkbox .p-checkbox-icon {
    display: flex; /* Mostrar o ícone padrão */
    font-size: 14px; /* Ajusta o tamanho do ícone conforme necessário */
    color: white !important; /* Cor do ícone para branco */
}

.approve-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon {
    display: flex; /* Mostrar o ícone quando selecionado */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Estilo para substituir o ícone padrão por um checkmark */
.approve-checkbox .p-checkbox-icon::before {
    content: "\2714"; /* Unicode para checkmark */
    font-size: inherit;
}


/* Estilos para o Checkbox de reprovação */
.request-checkbox .p-checkbox-box {
    border-color: gray; /* Cor da borda para vermelho */
}

.request-checkbox .p-checkbox-box.p-highlight {
    background-color: gray !important; /* Fundo vermelho quando selecionado */
    color: gray !important; /* Cor do ícone/texto para branco */
}

.request-checkbox .p-checkbox-icon {
    display: none; /* Esconde o ícone padrão */
}

.request-checkbox .p-checkbox-box.p-highlight:after {
    background-color: gray !important; /* Fundo vermelho quando selecionado */
    font-size: 14px; /* Ajusta o tamanho do X conforme necessário */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


