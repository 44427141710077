.p-datatable .p-checkbox {
    border-radius: 4px; /* Adiciona cantos arredondados ao checkbox */
  }
  
  .p-datatable .p-checkbox-box {
    border: 1px solid #ccc; /* Adiciona uma borda ao checkbox */
    border-radius: 3px; /* Adiciona cantos arredondados ao checkbox */
  }
  

  